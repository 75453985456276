<template>
    <div>
        <header class="main-header clearfix">
          <div class="container">
            <div class="row p-3">
              <div class="col-md-12">
                <a href="https://motomatic.ke"><img style="max-width: 200px;" src="@/client/assets/images/britam_logo.png" alt=""></a>
              </div>
            </div>
          </div>
        </header>

        <div class="stricky-header stricked-menu main-menu">
            <div class="sticky-header__content"></div><!-- /.sticky-header__content -->
        </div>
    </div>
</template>

<script>

    export default {
        name: "Header"
    }
</script>


<style scoped>

</style>
